﻿$modal-background-background-color: rgba($purple, 0.6);
$modal-content-width: 1080px;
$modal-card-head-background-color: #fff;
$modal-card-head-border-bottom: none;
$modal-card-head-padding: ($gap * 2);
$modal-card-title-color: #000;
$modal-card-body-padding: ($gap * 2);

@import '~bulma/sass/components/modal';

.modal-card {
	padding: 0 2.222rem;

	@include from ($tablet) {
		padding: 0 4.444rem;
	}

	&.is-small {
		width: 100%;
		padding: 0 1.111rem;

		@include from ($desktop) {
			width: 920px;
		}
	}
}

.modal-card-title {
	&.underline-after:after {
		left: 0 !important;
		transform: none !important;
	}
}

.modal-card-head {
	padding-bottom: 0;

	@include until($tablet) {
		padding-top: $gap;
	}
}

.modal-card-body {
	border-radius: 0 0 $radius-large $radius-large;
	font-size: 0.889rem;

	@include until($tablet) {
		padding: $gap;
	}

	picture {
		background: #fff;
	}
}

.modal-close {
	position: absolute;
	top: 0.222rem;
	right: 0.222rem;

	&:before,
	&:after {
		background: $pink-alt;
	}

	&:hover {
		background-color: rgba($pink-alt, 0.2);
	}
}

.modal-prev,
.modal-next {
	width: 2.778rem;
	height: 2.778rem;
	background: #fff;
	border-radius: 50%;
	color: $pink-alt;
	text-indent: -9999px;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	position: absolute;
	top: 50%;
	bottom: auto;
	transform: translateY(-50%);

	@include from($tablet) {
		width: 2.222rem;
		height: 2.222rem;
	}

	&:after {
		position: absolute;
		text-indent: 0;
		font-size: 0.666rem;
		top: 50%;
		transform: translateY(-50%);
	}

	&:hover {
		background-color: rgba($pink-alt, 0.2);
	}
}

.modal-prev {
	left: 1.111rem;

	&:after {
		@include icon($icon-chevron-left);
		left: 0.777rem;
	}
}

.modal-next {
	right: 1.111rem;

	&:after {
		@include icon($icon-chevron-right);
		right: 0.777rem;
	}
}