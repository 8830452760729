﻿// Typography
$family-sans-serif: 'Campton', sans-serif;
$family-primary: $family-sans-serif;
$size-1: 2.222rem;
$size-2: 1.778rem;
$size-3: 1.333rem;
$size-4: 1.111rem;
$size-5: 1rem;
$size-6: 0.889rem;
$size-7: 0.778rem;
//$size-7: 0.667rem;


// Spacing
$block-spacing: 10rem;

// Responsiveness
$gap: 20px;
$column-gap: 1.111rem; // 20px
$fullhd: 1440px;

// Flags
$variable-columns: false;

$radius-large: 1.111rem; // 20px

@import '~bulma/sass/utilities/initial-variables';
