﻿@import '~bulma/sass/form/tools';

.field.is-grouped {

	@include until($tablet) {
		display: block;
	}

	.control {
		width: auto;
	}

	> .control:not(:last-child) {
		@include until($tablet) {
			margin-bottom: .75rem;
		}
	}
}

.control.has-button {
	position: relative;

	button,
	.button {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		border: none;
		margin-right: 0;

		.icon {
			margin: 0 !important;
		}
	}
}