﻿@import '~bulma/sass/elements/content';

.content {
	&:not(:last-child) {
		margin-bottom: 1.5rem;
	}

	dd {
		margin-left: 0;
	}

	@include from($tablet) {

		dl {
			width: 100%;
		}

		dt,
		dd {
			display: inline-block;
		}

		dd {
			margin-left: 0.25rem;
		}
	}
}