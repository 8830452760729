﻿@import '~bulma/sass/elements/image';

img {
	vertical-align: top;

	&.border-radius {
		border-radius: $radius-large;
	}
}


.image {
	position: relative;

	&:not(.swiper-container-fade) {
		overflow: hidden;
	}
	// Responsive ratios
	&.is-3by1-tablet {
		@include from($tablet) {
			padding-top: (100% / 3) !important;
		}
	}

	&.instagram {
		img {
			object-fit: cover;
		}
	}

	&.is-portrait {
		padding-top: (626 / 440 * 100%);
		@extend %image-fill;
	}

	&.has-overflow {
		overflow: visible;
	}

	&.product-not-available {
		position: relative;
		overflow: visible;

		img {
			filter: blur(4px);
		}

		figcaption {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: 100%;
			color: $primary;
			font-weight: 500;
			text-shadow: 0 0 24px rgba(255,255,255,0.5);

			&:before {
				background: radial-gradient(ellipse farthest-side, rgba(255,255,255,0.5), rgba(255,255,255,0)) no-repeat 50% 50%;
				display: block;
				width: 100%;
				height: 6.666rem;
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	.product-placeholder {
		background: url(../../../images/product-placeholder.png) no-repeat 50% 50%;
		background-size: 75% auto;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.image-description {
		opacity: 0;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 1.111rem 2.222rem;
		transform: translateY(100%);
		transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;

		@media (hover: none) and (pointer: coarse) {
			opacity: 1;
			transform: translateY(0);
		}
	}

	&:hover {
		.image-description {
			opacity: 1;
			transform: translateY(0);
		}
	}
}

.extended-image {
	&.is-square {
		@include responsive-container-psudo(1, 1);
	}

	&.is-2by3 {
		@include responsive-container-psudo(2, 3);
	}

	&.is-3by2 {
		@include responsive-container-psudo(3, 2);
	}

	&.is-4by3 {
		@include responsive-container-psudo(4, 3);
	}

	&.is-16by9 {
		@include responsive-container-psudo(16, 9);
	}

	&.is-portrait {
		@include responsive-container-psudo(.75, 1);
	}

	img, &div, video {
		width: 100%;
		height: auto;
	}

	@include from($desktop) {
		&.is-100-l {
			width: 100%;
		}

		&.is-75-l {
			width: 75%;
		}

		&.is-50-l {
			width: 50%;
		}

		&.is-33-l {
			width: calc(100% / 3);
		}

		&.is-25-l {
			width: 25%;
		}
	}

	&.is-centered-true {
		margin: 0 auto;
	}
}

