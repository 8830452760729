﻿@import '~bulma/sass/form/checkbox-radio';
@import "../../custom/icon";

.dataconsent,
.checkbox {
	margin: -0.278rem 0 0;

	input {
		display: none;

		&:checked + label {
			&:before {
				@include icon($icon-check);
				color: $primary;
			}
		}
	}

	label {
		display: block;
		line-height: $body-line-height * 0.75;
		position: relative;
		padding: 0 0 0 2rem;
		cursor: pointer;

		&:before {
			position: absolute;
			content: '';
			width: 1.111rem;
			height: 1.111rem;
			padding: 0.222rem 0 0;
			top: 0.111rem;
			left: 0;
			font-size: 0.722rem;
			text-align: center;
			background: #fff;
		}

		p {
			margin: 0;
		}

		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}

		&:hover {
			color: $primary;

			&:before {
				@include icon($icon-check);
				color: rgba($primary, 0.5);
			}
		}
	}

	&.isnew {
		vertical-align: top;
		margin: 0;

		label {
			width: 3rem;
			height: 3rem;
			background: url(../../../images/filter-isnew.svg) no-repeat 0 0;
			background-size: 100% auto;
			padding: 0;
			margin: -0.375rem 0;

			&:hover {
				opacity: 0.75;
			}

			&:before {
				display: none;
			}
		}

		input:checked + label {
			background-position: 0 100%;
		}
	}
}
