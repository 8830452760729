﻿$input-color: $primary;
$input-border-color: $primary;
$input-focus-box-shadow-size: 0 0 1rem;
$input-placeholder-color: $primary;

@import '~bulma/sass/form/shared';

.input,
.select,
.textarea {
	.route-form & {
		&::placeholder {
			opacity: 1;
		}
	}
}

legend {
	font-weight: 700;
}

.umbraco-forms-tooltip {
	font-size: 0.75rem;
}

.forms-message {
	background: rgba(0,0,0,0.2);
	padding: 2.5rem 1.25rem;
	font-size: 1.25rem;
	line-height: 1.5rem;
	border-radius: $radius-large;
}

// Hide honeypot fieldset/field
#fieldset-e1afdf62-b46e-40ee-8793-fe69120e747a,
.umbraco-forms-field.email2 {
	display: none;
}