﻿$spacing-range-start: 0;
$tooltip-font-size: $size-6;
$tooltip-max-width: 24.444rem;
$tooltip-padding: ($gap * 2);
$tooltip-radius: $radius-large;

@import '~bulma-helpers/css/bulma-helpers.min.css';
@import '~bulma-extensions/bulma-tooltip/src/sass/index.sass';
@import '~bulma-popover/css/bulma-popover.css';


.popover {
	.popover-content {
		box-sizing: border-box;
		width: calc(100vw - 20px);
		max-width: $tooltip-max-width;
		padding: $tooltip-padding;
		border-radius: $tooltip-radius;
		margin-top: 0.666rem;
		font-size: $tooltip-font-size;
		line-height: 1.5;

		&:before {
			display: none;
		}

		.title.underline-after:after {
			left: 0 !important;
			transform: none !important;
		}

		p:last-of-type {
			margin-bottom: 0;
		}
	}

	&:hover {
		.popover-content {
			opacity: 0;
			visibility: hidden;
		}
	}

	&.is-active {
		.popover-content {
			opacity: 1;
			visibility: visible;
		}
	}

	.popover-close {
		@extend .modal-close;

		&:before,
		&:after {
			background: $primary;
		}

		&:hover {
			background: rgba($primary, 0.2);
		}
	}
}

.has-no-padding {
	padding: 0 !important;
}

.has-no-padding-bottom {
	padding-bottom: 0 !important;
}

.has-no-padding-top {
	padding-top: 0 !important;
}

.has-no-margin {
	margin: 0 !important;
}

.has-no-margin-bottom {
	margin-bottom: 0 !important;
}

.has-no-margin-top {
	margin-top: 0 !important;
}

.has-letter-spacing {
	letter-spacing: 0.08em !important;
}
