﻿$variable-columns: true;

@import '~bulma/sass/grid/columns';

.columns {
	&.is-variable {
		.column {
			padding: var(--columnGap);
		}
	}

	&.is-widescreen.feed {
		@include until($widescreen) {
			.column:first-child {
				width: 100%;
			}
		}
	}

	.title + & {
		margin-top: 0;
	}

	&.image-align-left {
		flex-direction: row-reverse;

		@include from($desktop) {
			.column-content {
				padding-right: 0;
				padding-left: ($gap * 4);
			}
		}
	}

	@include from($desktop) {
		.column-content {
			padding-right: ($gap * 4);
		}
	}

	.modal & {
		&:last-child {
			margin-bottom: 0;
		}
	}

	@include from($widescreen) {
		&.feed {
			.column.is-6 {
				+ .column {
					order: -1;
				}
			}
		}
	}
}

.column {
	&.swiper-slide {
		flex: none;
	}

	&.is-6-mobile-large {
		@include until(768px) {
			width: 50% !important;
		}
	}

	&.is-12-mobile {
		@include until(480px) {
			width: 100% !important;
		}
	}
}