﻿$button-color: #fff;
$button-background-color: $primary;
$button-border-color: $primary;
$button-border-width: 2px;
$button-padding-horizontal: 1.111rem;
$button-hover-color: $white;
$button-hover-border-color: $primary;
$button-disabled-background-color: rgba(#000, 0.1);

@import '~bulma/sass/elements/button';
@import "../../custom/icon";

button,
.button {
	font-size: 80%;
	font-weight: 500;
	text-transform: uppercase;
	text-decoration: none;
	height: 2.222rem;
	border-radius: $radius-large;
	transition: background 250ms ease-in-out 0s, color 250ms ease-in-out 0s, border-color 250ms ease-in-out 0s;

	&:hover,
	&:focus,
	&:active {
		background: $purple-alt-dark;
		border-color: $purple-alt-dark;
		text-decoration: none;
	}

	&.small {
		font-size: 0.611rem;
		height: 1.333rem;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	&.arrow {
		padding-right: 1.889rem;

		&:after {
			@include icon($icon-chevron-right);
			font-size: 0.625rem;
			position: absolute;
			top: 50%;
			right: 0.889rem;
			transform: translateY(-50%);
			transition: right 250ms ease-in-out 0s;
			transition-delay: 250ms;
		}

		&:hover,
		&:focus,
		&:active {
			&:after {
				right: 0.75rem;
			}
		}
	}

	$colors: "purple" $purple $purple-dark, "purple-alt" $purple-alt $purple-alt-dark, "pink" $pink $pink-dark, "pink-alt" $pink-alt $pink-alt, "yellow" $yellow $yellow-dark, "blue" $blue $blue-dark;

	@each $name, $color, $color-dark in $colors {
		.#{$name} &,
		&.#{$name} {
			background-color: #{$color-dark};
			border-color: #{$color-dark};

			&.outline {
				color: #{$color-dark};
				background: none;
				border-color: #{$color-dark};
			}

			&:hover,
			&:focus,
			&:active {
				background: #{$color-dark};
				color: #fff;
			}
		}
	}

	&.white {
		background: #fff;
		border-color: #fff;
		color: $primary;

		&:hover,
		&:focus,
		&:active {
			color: $purple-alt-dark;
		}

		&.outline {
			color: #fff;

			&:hover,
			&:focus,
			&:active {
				background: #fff;
				color: $primary;
			}
		}
	}

	&.outline {
		background: none;
		color: $primary;
	}

	.icon {
		margin-right: 1.111rem !important;
	}

	&.reset {
		width: 2.222rem;
		border: none;
		font-size: 0;
		text-indent: -9999px;

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			background-color: $pink-alt;
			transform: translateX(-50%) translateY(-50%) rotate(45deg);
			transform-origin: center center;
			transition: background-color 250ms ease-in-out;
		}

		&:before {
			height: 2px;
			width: 50%;
		}

		&:after {
			height: 50%;
			width: 2px;
		}

		&:hover {
			background-color: $pink-alt;

			&:before,
			&:after {
				background-color: #fff;
			}
		}
	}
}

.buttons {
	&.has-text-centered,
	.has-text-centered & {
		justify-content: center;
	}

	&.has-text-centered-tablet,
	.has-text-centered-tablet & {
		@include from($tablet) {
			justify-content: center;
		}
	}
}
