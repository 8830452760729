﻿//@import '~bulma/sass/elements/box';
@import 'button';
@import '~bulma/sass/elements/container';
@import 'content';
//@import '~bulma/sass/elements/icon';
@import 'image';
@import '~bulma/sass/elements/notification';
//@import '~bulma/sass/elements/progress';
//@import '~bulma/sass/elements/table';
//@import '~bulma/sass/elements/tag';
@import 'title';

//@import '~bulma/sass/elements/other';

.notification:not(:last-child) {
	margin-bottom: 1.111rem;
}