﻿$navbar-background-color: none;
$navbar-box-shadow-size: 0;
//$navbar-box-shadow-color: $background !default
$navbar-height: 2.222rem;
//$navbar-padding-vertical: 1rem !default
//$navbar-padding-horizontal: 2rem !default
$navbar-z: 2147483647;
//$navbar-fixed-z: 30 !default
$navbar-item-color: #fff;
$navbar-item-hover-color: #fff;
$navbar-item-hover-background-color: rgba(#fff, 0.1);
$navbar-item-active-color: #fff;
$navbar-item-active-background-color: rgba(#fff, 0.1);
//$navbar-item-img-max-height: 1.75rem !default

$navbar-burger-color: $primary;

//$navbar-tab-hover-background-color: transparent !default
//$navbar-tab-hover-border-bottom-color: $link !default
//$navbar-tab-active-color: $link !default
//$navbar-tab-active-background-color: transparent !default
//$navbar-tab-active-border-bottom-color: $link !default
//$navbar-tab-active-border-bottom-style: solid !default
//$navbar-tab-active-border-bottom-width: 3px !default
$navbar-dropdown-background-color: $primary;
$navbar-dropdown-border-top: none;
//$navbar-dropdown-offset: -4px !default
//$navbar-dropdown-arrow: $link !default
$navbar-dropdown-radius: $radius-large;

$navbar-dropdown-boxed-radius: $radius-large;
$navbar-dropdown-boxed-shadow: 0;

//$navbar-dropdown-item-hover-color: $black !default
//$navbar-dropdown-item-hover-background-color: $background !default
//$navbar-dropdown-item-active-color: $link !default
//$navbar-dropdown-item-active-background-color: $background !default

//$navbar-divider-background-color: $background !default
//$navbar-divider-height: 2px !default

//$navbar-bottom-box-shadow-size: 0 -2px 0 0 !default

$navbar-breakpoint: 200000px;

@import '~bulma/sass/components/navbar';
@import "../../custom/icon";

.navbar {
	float: right;
	top: -0.444rem;
}

.navbar-burger {
	width: auto;
	padding-left: $navbar-height;
	line-height: $navbar-height;
	font-size: 0.875rem;
	text-transform: uppercase;

	&:hover {
		color: $purple-alt-dark;
		background: none;
	}

	&.is-active {
		color: $primary;
		z-index: 10;

		span,
		p {
			color: #fff;
		}
	}

	span {
		width: 1.778rem;
		left: 0;
		height: 2px;
	}
}

.navbar-menu {
	display: block;
	padding: 0 !important;
	border-top: 1px solid rgba(#fff, 0.2);
	margin-bottom: 1.111rem;
	box-shadow: none !important;

	@include from($desktop) {
		margin-bottom: 2.222rem;
	}

	> .navbar-item {
		border-bottom: 1px solid rgba(#fff, 0.2);
	}

	.navbar-link {
		text-decoration: none;

		&:hover {
			background: $navbar-item-hover-background-color;
			color: $navbar-item-hover-color;
		}
	}

	.navbar-item {
		font-weight: 500;
		font-size: $size-6;
		text-transform: uppercase;
		text-decoration: none;
		padding: 0.889rem $gap 0.833rem;

		&:hover {
			background: $navbar-item-hover-background-color;
			color: $navbar-item-hover-color;
		}

		&.has-dropdown {
			padding: 0;

			.navbar-link {
				padding: 0.889rem $gap 0.833rem;

				+ .icon {
					display: block;
					position: absolute;
					width: 2.778rem;
					height: 3rem;
					padding-top: 1.125rem;
					border-left: 1px solid rgba(#fff, 0.2);
					top: 0;
					right: 0;
					@include icon($icon-chevron-down);
					font-size: 0.625rem;
					text-align: center;
				}

				&.is-active {
					+ .navbar-dropdown,
					+ .icon + .navbar-dropdown {
						display: block;
						background: rgba(#fff, 0.1);
						border-top: 1px solid rgba(#fff, 0.2);
					}

					+ .icon:before {
						display: inline-block;
						transform: rotate(180deg);
					}
				}
			}

			.navbar-dropdown {
				display: none;
				padding: 0;

				.navbar-item {
					font-size: $size-7;
				}
			}
		}
	}
}