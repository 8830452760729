﻿$card-background-color: rgba($primary, 0.1);
$card-shadow: none;
$card-content-padding: ($gap * 2) ($gap * 2) ($gap * 2) ($gap * 2);

@import '~bulma/sass/components/card';

.card {
	background-image: url(../../../images/background-card.jpg);
	background-repeat: no-repeat;
	background-position: 100% -190px;
	border-radius: $radius-large;
	height: 100%;
	overflow: hidden;

	> a {
		text-decoration: none;
		display: block;
		height: 100%;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}

	&.image-placeholder {
		background-image: url(../../../images/background-card.jpg), url(../../../images/background-card.jpg);
		background-repeat: no-repeat;
		background-position: 100% calc(100% + 190px), 100% -190px;
	}

	&.has-no-background {
		background: none;
		border-radius: 0;
	}

	&.product-card {
		cursor: pointer;

		&:hover {
			.card-image {
				&:after {
					width: 30%;
				}

				&.is-large-flower {
					&:after {
						width: 50%;
					}
				}

				&:not(.is-mix) {
					figure {
						transform: translateY(-0.5rem);
					}
				}
			}
		}

		.title:not(.modal-card-title),
		.subtitle {
			color: $primary;
			font-weight: 500;

			+ .subtitle {
				margin-top: -1rem;
				color: $primary-light;
			}
		}

		.card-content {
			padding: 0;
		}

		.card-image {
			padding: $gap $gap ($gap + 10px);
			margin-top: -$gap;
			position: relative;

			&:after {
				content: '';
				background: url(../../../images/shadow.png) no-repeat 50% 100%;
				background-size: 100% auto;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 40%;
				height: 20px;
				transition: all 500ms ease 0s;
			}

			.product-slider &,
			&.is-large-flower {
				margin-top: 0;

				&:after {
					width: 66%;
				}

				.product-placeholder {
					background-size: 100% auto;
				}
			}

			&.is-mix {
				&:after {
					display: none;
				}
			}

			figure {
				transition: all 500ms ease 0s;
			}
		}
	}
}

.card-label {
	background: url(../../../images/label-new.svg) no-repeat;
	background-size: 100% auto;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 3.333rem;
	height: 3.333rem;
	width: (100% / 3);
	height: (100% / 3);

	&.is-improved {
		background-image: url(../../../images/label-improved.svg);
	}
}

.card-image + .card-content {
	opacity: 0;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	transform: translateY(100%);
	transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;

	.is-hoverable:hover &,
	a:hover & {
		opacity: 1;
		transform: translateY(0);
	}

	@media (hover: none) and (pointer: coarse) {
		opacity: 1;
		transform: translateY(0);
	}
}
