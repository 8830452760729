﻿$primary: #827850;
$primary-invert: findColorInvert($primary);
$primary-light: rgba($primary, 0.5);
$purple: #dfcacf;
$purple-dark: #733753;
$purple-alt: #e3b9d1;
$purple-alt-dark: #b9508c;
$pink: #f3d7d1;
$pink-dark: #a06a5e;
$pink-alt: #ae7b87;
$pink-alt-dark: #684a51;
$yellow: #f2dab3;
$yellow-dark: #987031;
$blue: #c0d3e3;
$blue-dark: #426481;
$text: #000;
$text-strong: inherit;
$link: $text;
$link-visited: $link;
$link-hover: $primary;
$link-hover-border: inherit;
$link-focus: inherit;
$link-focus-border: inherit;
$link-active: inherit;
$link-active-border: inherit;

@import '~bulma/sass/utilities/derived-variables';
