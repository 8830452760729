﻿$input-shadow: none;

@import '~bulma/sass/form/input-textarea';

.input,
.input.is-rounded,
.textarea {
	padding: 0 $gap;
	border-color: rgba($primary, 0.3);

	&.border-radius {
		border-radius: $radius-large;
	}

	.filter & {
		color: $pink-alt;
		border-color: #fff;

		&::placeholder {
			color: $pink-alt;
			opacity: 1;
		}
	}
}

.field.is-grouped {
	.control {
		width: 100%;

		@include from($desktop) {
			width: auto;

			&:first-of-type {
				width: 100%;
				flex-shrink: 1;
			}
		}
	}
}