﻿$title-weight: 600;
$subtitle-weight: 300;
$subtitle-negative-margin: -0.556rem;

@import '~bulma/sass/elements/title';

.title,
.subtitle {
	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	&.is-primary {
		color: #84754e;
	}
}

.title {
	margin-bottom: 1rem;

	&.has-subtitle.underline-after {
		padding-bottom: 4.444rem;

		+ .subtitle {
			position: relative;
			top: -3.889rem;
		}
	}

	&.underline-after {
		padding-bottom: 3rem;
		position: relative;

		&:after {
			background: url(../../../images/title-underline.svg) no-repeat;
			background-size: cover;
			display: inline-block;
			content: '';
			width: 120px;
			height: 4px;
			position: absolute;
			bottom: 0;
			left: 0;

			.has-text-centered & {
				left: 50%;
				transform: translateX(-50%);
			}

			@include from($tablet) {
				.has-text-centered-tablet & {
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}

		&.is-2 {
			padding-bottom: 2rem;
		}

		&.is-3 {
			padding-bottom: 1.111rem;
		}

		&.underline-after-small:after {
			width: 60px;
		}
	}
}