﻿@import '~bulma/sass/form/select';

.select.is-primary {
	width: 100%;

	select {
		width: 100%;
		border-color: rgba($primary, 0.3);
	}
}

.filter {
	.select,
	select {
		width: 100%;
		color: $pink-alt;
		border-color: #fff;

		&:not(.is-multiple):not(.is-loading):after {
			border-color: $pink-alt;
			border-width: 2px;
		}
	}
}