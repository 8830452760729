﻿$footer-background-color: none;
$footer-color: $primary;
$footer-padding: $gap;

@import '~bulma/sass/layout/footer';

.footer {
	min-height: 11.111rem;

	a {
		color: $footer-color;
		text-decoration: none;
	}
}