﻿@import "~swiper/css/swiper.css";

// Wrapper
.swiper-wrapper {
	.image & {
		overflow: hidden;
	}

	figure & {
		position: absolute;
		top: 0;
		left: 0;
	}
}

.swiper-slide {
	box-sizing: border-box;

	&.product-card {
		padding: ($column-gap * 1.5);
	}

	.image-slider & {
		height: auto;
		display: flex;
		flex-direction: column;

		figure {
			margin-top: auto;
		}
	}
}

.swiper-container-horizontal {
	&.product-slider,
	&.image-slider {
		overflow: hidden;
		margin-right: -40px !important;
		margin-left: -40px !important;
		position: relative;
	}
}

// Navigation
.swiper-button-prev,
.swiper-button-next {
	top: 50%;
	width: 3rem;
	height: 3rem;
	margin-top: -(3rem / 2);
	border-radius: 50%;
	color: $primary;
	line-height: 3rem;
	text-align: center;
	text-decoration: none;
	background: #fff;
	transition: all 150ms ease-in-out;

	&::after {
		@include icon;
		font-size: 1rem;
	}

	&:hover,
	&:focus {
		&::after {
			color: $purple-alt-dark;
		}
	}

	&.swiper-button-disabled {
		opacity: 0.2;
	}
}

.swiper-button-prev {
	left: 1rem;

	&::after {
		content: $icon-chevron-left;
	}
}

.swiper-button-next {
	right: 1rem;

	&::after {
		content: $icon-chevron-right;
	}
}

// Pagination
.swiper-pagination-bullet {
	position: relative;
	width: 1.333rem;
	height: 1.333rem;
	background: none;
	opacity: 1;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		background: none;
		width: 50%;
		height: 50%;
		border: 2px solid #fff;
		border-radius: 100%;
		margin: 18.75%;
		transition: background-color 0.2s ease-in-out;

		.modal & {
			border-color: $pink-alt;
		}
	}

	&:hover,
	&:focus,
	&:active {
		&:before {
			background: #fff;

			.modal & {
				background-color: $pink-alt;
			}
		}
	}
}

.swiper-pagination-bullet-active:before {
	background: #fff;

	.modal & {
		background-color: $pink-alt;
	}
}

.swiper-container-horizontal {
	& > .swiper-pagination-bullets {
		bottom: 1.667rem;

		.modal & {
			bottom: -2.222rem;
		}

		.swiper-pagination-bullet {
			margin: 0;
		}
	}
}
