﻿@import '~bulma/sass/layout/hero';

.hero-video {
	display: none !important;

	&:before {
		position: absolute;
		top: 0;
		z-index: 1;
		width: 100%;
		height: 18rem;
		background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
		content: '';
	}

	@include from($tablet) {
		display: block !important;
	}

	@include from($desktop) {
		height: 100vh;
	}

	&.image iframe.has-ratio {
		height: 120%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		@include until($widescreen) {
			width: 150%;
			max-width: 200%;
		}
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	@at-root {
		@keyframes skip-pulse {
			0% {
				bottom: 1.5rem;
			}

			50% {
				bottom: 2.222rem;
			}

			100% {
				bottom: 1.5rem;
			}
		}
	}

	@include from($tablet) {
		a.skip {
			background: #fff;
			position: absolute;
			z-index: 2;
			bottom: 2.222rem;
			left: 50%;
			transform: translateX(-50%);
			font-size: 75%;
			text-align: center;
			text-decoration: none;
			width: 2.222rem;
			height: 2.222rem;
			border-radius: 1.111rem;
			box-shadow: 0 0 1.111rem rgba($primary, 0.3);
			animation: 2s infinite skip-pulse;

			&:before {
				display: block;
				@include icon($icon-chevron-down);
				color: $primary;
				padding-top: 0.8125rem;
				transition: color 250ms ease-in-out;
			}

			&:hover {
				&:before {
					color: $pink-alt;
				}
			}
		}

		&.bottom a.skip {
			position: fixed;
		}
	}
}