﻿@font-face {
	font-family: 'Campton';
	src: url('../../fonts/Campton-Light.woff2') format('woff2'), url('../../fonts/Campton-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Campton';
	src: url('../../fonts/Campton-Medium.woff2') format('woff2'), url('../../fonts/Campton-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Campton';
	src: url('../../fonts/Campton-SemiBold.woff2') format('woff2'), url('../../fonts/Campton-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}
