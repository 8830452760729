﻿$backdrop-opacity: 0.8;
$lg-toolbar-bg: none;
$lg-theme: $primary;

$lg-icon-bg: transparent;
$lg-icon-color: $purple-dark;
$lg-icon-hover-color: mix(#000, $purple-dark);

$lg-counter-color: $purple-alt;

$lg-sub-html-bg: rgba($purple, 0.6);
$lg-sub-html-color: $purple-dark;

@import "~lightgallery/src/sass/lightgallery";

.lg-backdrop,
.lg-outer {
	background: rgba($purple, 0.6);
}

.lg-object {
	max-height: 100vh;
}

@include from($tablet) {
	.lg-object {
		padding: ($gap * 2);
	}
}