﻿@import "../custom/rem";

.languages {
	display: inline-block;
	position: fixed;
	padding: 0;
	margin: 0;
	border-radius: 0 50% 50% 0;
	top: rem(32px);
	left: 0;
	cursor: pointer;
	color: $primary;
	background: #fff;

	html[data-lock="1"] & {
		display: none;
	}

	@include from($tablet) {
		position: relative;
		padding: 0 rem(8px);
		margin: 0 rem(8px) 0 rem(-8px);
		border-radius: 0;
		top: rem(-10px);
		background: none;

		@media(hover: hover) and (pointer: fine) {
			padding: 0;

			body.light & {
				color: $primary;
			}

			&hover,
			&:focus,
			&.open {
				dd {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	&.open {
		dd {
			opacity: 1;
			visibility: visible;
			box-shadow: 0 0 rem(24px) 0 rgba(0,0,36,0.25);
		}
	}

	dt {
		display: block;
		position: relative;
		width: rem(42px);
		height: rem(42px);
		text-indent: -9999px;

		@include from($tablet) {

			@media(hover: hover) and (pointer: fine) {
				background: none;
				padding: 0;
			}
		}

		&:after {
			@include icon($icon-globe);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: rem(20px);
			text-indent: 0;
		}
	}

	dd {
		background: #fff;
		margin: 0;
		opacity: 0;
		visibility: hidden;
		position: absolute;
		left: 0;

		@include from($tablet) {
			right: 0;
			left: auto;

			@media(hover: hover) and (pointer: fine) {
				top: calc(100% + 10px);
				transform: none;
				box-shadow: 0 0 rem(24px) 0 rgba(0,0,36,0.25);
				transition: all 250ms ease-in-out;

				&::before {
					position: absolute;
					content: "";
					width: 0;
					height: 0;
					border-color: transparent transparent #fff;
					border-style: solid;
					border-width: 0 rem(12px) rem(12px);
					top: rem(-12px);
					right: rem(10px);
				}
			}
		}

		ul {
			list-style: none;
			padding: rem(4px) 0;
			margin: 0;

			li {
				a {
					display: block;
					padding: rem(2px) rem(24px);
					font-size: rem(16px);
					text-decoration: none;
					color: $primary;

					@include from($tablet) {


						@media(hover: hover) and (pointer: fine) {


							body.dark & {
								color: $primary;
							}
						}
					}
				}
			}
		}
	}
}
