﻿$section-padding: 0 $gap;

@import '~bulma/sass/layout/section';

.section {
	/*overflow: hidden;*/
	
	> .container {
		padding: 2.222rem 0;

		@include from($desktop) {
			padding: 4.444rem 0;
		}
	}

	+ .section {
		> .container {
			border-top: 1px solid rgba($primary, 0.3);
		}
	}

	&.has-border-top {
		border-top: 1px solid rgba($primary, 0.3);

		> .container {
			border-top: none;
		}

		+ .section {
			> .container {
				border-top: none;
			}
		}
	}

	&.has-no-padding {
		> .container,
		+ .section > .container {
			padding: 0;
		}
	}

	&.has-no-padding-top {
		> .container,
		+ .section > .container {
			padding-top: 0;
			border: none;
		}
	}

	&.image-text-block {
		@extend %image-fill;
		background: $primary;

		.container {
			position: relative;
			z-index: 1;
		}

		.wrapper {
			background: rgba(#fff, 0.8);
			padding: ($column-gap * 2);
			border-radius: $radius-large;
		}
	}
}