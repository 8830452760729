$icon-assortment: '\ea01';
$icon-check: '\ea02';
$icon-chevron-down: '\ea03';
$icon-chevron-left: '\ea04';
$icon-chevron-right: '\ea05';
$icon-chevron-up: '\ea06';
$icon-dna: '\ea07';
$icon-eye: '\ea08';
$icon-facebook: '\ea09';
$icon-globe: '\ea0a';
$icon-innovation: '\ea0b';
$icon-instagram: '\ea0c';
$icon-linkedin: '\ea0d';
$icon-pinterest: '\ea0e';
$icon-play: '\ea0f';
$icon-search: '\ea10';
$icon-service: '\ea11';
$icon-sustainability: '\ea12';
$icon-twitter: '\ea13';
$icon-vimeo: '\ea14';
$icon-youtube: '\ea15';

@at-root {
	@font-face {
		font-family: icon;
		
		src: url('../../fonts/icon.woff2') format('woff2'), url('../../fonts/icon.woff') format('woff'); 
		font-style: normal;
		font-weight: normal;
		font-display: block;
	}
}

@mixin icon($content: none) {
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	font-family: icon;
	line-height: 1;
	text-transform: none;
	vertical-align: middle;
	speak: none;
	content: $content;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon:before {
	@include icon;
}

.icon-assortment:before { content: $icon-assortment; }
.icon-check:before { content: $icon-check; }
.icon-chevron-down:before { content: $icon-chevron-down; }
.icon-chevron-left:before { content: $icon-chevron-left; }
.icon-chevron-right:before { content: $icon-chevron-right; }
.icon-chevron-up:before { content: $icon-chevron-up; }
.icon-dna:before { content: $icon-dna; }
.icon-eye:before { content: $icon-eye; }
.icon-facebook:before { content: $icon-facebook; }
.icon-globe:before { content: $icon-globe; }
.icon-innovation:before { content: $icon-innovation; }
.icon-instagram:before { content: $icon-instagram; }
.icon-linkedin:before { content: $icon-linkedin; }
.icon-pinterest:before { content: $icon-pinterest; }
.icon-play:before { content: $icon-play; }
.icon-search:before { content: $icon-search; }
.icon-service:before { content: $icon-service; }
.icon-sustainability:before { content: $icon-sustainability; }
.icon-twitter:before { content: $icon-twitter; }
.icon-vimeo:before { content: $icon-vimeo; }
.icon-youtube:before { content: $icon-youtube; }
